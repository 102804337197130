import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import AnimatedHr from '../components/animatedHr'

const AboutUs = () => (
  <Layout>
    <div className="headerImage ferric">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>Ferric Meters</h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>
            <p>Your company for industrial electronics repairs in Sheffield</p>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div className="container">
        <div className="row">
          <div className="six columns">
            <p>
              Calibrated to distinguish between Commercially pure (CP) Titanium
              and Titanium Alloy. It instantly gives a positive or negative
              reading to the sample being tested, saving time and accurately
              differentiating between CP Titanium and Titanium Alloy.
            </p>

            <p>Price and availability on request.</p>
            <p>Designed by D. Davis</p>
          </div>
          <div className="six columns">
            <div className="serviceBenefits">
              <h3>Nova's principles for pricing</h3>
              <div
                style={{
                  padding: '20px',
                }}
              >
                <p>
                  Nova Electronics have three principles about keeping your
                  repair costs low
                </p>

                <ol>
                  <li>
                    We talk with you about the problem and the likely costs. We
                    won’t hide anything. We are known for our honesty
                  </li>
                  <li>
                    We only do what is necessary to fix the problem – we don’t
                    do “gold-plating”
                  </li>
                  <li>
                    If something else is found or is needed – we will call you
                    first to discuss it.
                  </li>
                </ol>

                <Link to="/contact-us" className="button">
                  Book in your Repair
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutUs
